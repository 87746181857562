<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box px-6">
				<p class="txt_body1 mb-8">
					납부 수단별 금액을 입력해 주세요.
					<br />납부 금액은
					<span class="primary--text"> {{ dpParams.amount | comma }} 원</span
					>입니다.
				</p>
			</div>

			<v-form class="form px-6" ref="recpComplexForm">
				<div class="wrap_input_withLabel_01">
					<h3 class="label">신용카드 납부</h3>
					<vuetify-money
						class="wcalc90"
						outlined
						dense
						v-model="cardAmount"
						valueWhenIsEmpty="0"
						:options="options"
						:rules="rules.cardAmount"
						:valueOptions="valueOptions"
						:properties="{
							inputmode: 'numeric',
							maxlength: 10,
							rules: 'rules.cardAmount'
						}"
						@CustomMinEvent="handleMinAcptAmt($event)"
						@CustomMaxEvent="handleMaxAcptAmt($event)"
						ref="cardAmt"
					/>
				</div>
				<div class="wrap_input_withLabel_01">
					<h3 class="label">가상계좌 납부</h3>
					<vuetify-money
						class="wcalc90"
						outlined
						dense
						disabled
						v-model="vaccAmount"
						valueWhenIsEmpty="0"
						:options="options"
					/>
				</div>
			</v-form>
			<div class="bottom_box px-6">
				<v-btn
					color="primary"
					class="col_1_small"
					@click="next()"
					:disabled="isDisableBtn"
				>
					입력 완료
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
			</div>
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { required, numeric } from '@/utils/rules'
export default {
	mixins: [filters],
	data() {
		return {
			options: { precision: 0, length: 8 },
			rules: {
				cardAmount: [
					v => required(v ? v.replaceAll(',', '') : v),
					v => numeric(v ? v.replaceAll(',', '') : v),
					v => this.chkCardAmount(v ? v.replaceAll(',', '') : v)
				]
			},
			cardAmount: 0,
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객납부
			},
			vaccountParams: {
				TC_VTACCT: {},
				TO_DISPLAY: []
			},
			dpParams: {
				account: '',
				date: '',
				amount: ''
			},
			valueOptions: {
				min: 1,
				minEvent: 'CustomMinEvent',
				max: 0,
				maxEvent: 'CustomMaxEvent'
			}
		}
	},
	computed: {
		vaccAmount() {
			return Number(this.recpInfos.amount) - Number(this.cardAmount)
		},
		isDisableBtn() {
			let is = false
			if (this.vaccAmount < 1 || this.cardAmount < 1) {
				is = true
			}
			return is
		}
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']
		this.valueOptions.max = Number(this.recpInfos.amount)
		this.vaccountParams = this.$store.getters['recp/vaccountParams']

		this.$log(
			'복합 가상계좌생성 후 납부 여부 isNew: ',
			this.vaccountParams.isNew
		)

		if (this.vaccountParams.isNew) {
			// 가상계좌생성 후 납부
			this.dpParams.account = this.vaccountParams.TO_VTINFO.SUACC
			this.dpParams.date = ''
			this.dpParams.amount = this.recpInfos.amount
		} else {
			this.vaccountParams.TO_DISPLAY.forEach(dp => {
				const txt = dp.ZWEB_DIS_TEXT.trim()

				switch (dp.ZWEB_DIS_CD) {
					case '33':
						this.dpParams.account = dp.ZWEB_DIS_TEXT
						break
					case '40':
						this.dpParams.date = dp.ZWEB_DIS_TEXT
						break
					case '51':
						this.dpParams.amount = txt.substr(0, txt.indexOf('.'))
						break
				}
			})
		}
	},
	methods: {
		handleMinAcptAmt(minAmt) {
			this.$log('handleMinAcptAmt', minAmt)
			this.cardAmount = minAmt
		},
		handleMaxAcptAmt(maxAmt) {
			this.$log('handleMaxAcptAmt', maxAmt)
			this.cardAmount = maxAmt
		},
		chkCardAmount(val) {
			this.$log(val)
			if (val) {
				if (this.vaccAmount < 1 || this.cardAmount < 1) {
					return '입력하신 금액을 확인해 주세요.'
				} else {
					return true
				}
			} else {
				return true
			}
		},
		onResetPayMethod() {
			this.$router.go(-1)
		},
		next() {
			if (!this.$refs.recpComplexForm.validate()) return false
			this.recpInfos.cardAmount = this.cardAmount
			this.recpInfos.vaccAmount = this.vaccAmount
			this.$router.push({ name: 'recp-cust-complex-card' })
		}
	}
}
</script>
